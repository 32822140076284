<template>
  <div class="app-ecommerce">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12 col-lg-5">
          <div class="card mb-4">
            <div class="card-header">
              <h4 class="card-tile mb-2">Configuration Termes</h4>
              <label
                >Les termes d’attributs peuvent être assignés aux produits et
                variations.</label
              >
              <label style="margin-top: 15px"
                >Note : Supprimer un terme le retirera de tous les produits et
                de toutes les variations auxquels il était associé. Recréer le
                terme ne l’assignera pas de nouveau aux produits..</label
              >
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="ecommerce-product-name"
                  >Nom</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nom Terme"
                  name="productTitle"
                  aria-label="Product title"
                  v-model="nomTerme"
                />
                <label
                  >Le nom est la façon dont il apparaît sur votre site.</label
                >
              </div>
              <div class="mb-3">
                <label class="form-label" for="ecommerce-product-name"
                  >Slug</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Slug"
                  name="productTitle"
                  aria-label="Product title"
                  v-model="slugTerme"
                />
                <label
                  >Le « slug » est la version du nom normalisée pour les
                  URL.</label
                >
              </div>

              <div class="d-flex flex-row-reverse mt-3">
                <button
                  type="submit"
                  class="btn btn-primary"
                  @click="confirmAddTerme()"
                  :disabled="nomTerme.length === 0"
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-7">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="card-title mb-0">Liste Termes</h3>
            </div>

            <div>
              <b-table
                :items="listTermes"
                :fields="fields"
                :busy="isBusy"
                striped
                hover
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong style="margin-left: 5px">Loading...</strong>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-edit-icon`"
                      icon="EditIcon"
                      class="mx-1"
                      @click="getTermeInfo(data.item)"
                    />
                    <b-tooltip
                      title="Modification"
                      :target="`invoice-row-${data.item.id}-edit-icon`"
                    />
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-trash-icon`"
                      icon="TrashIcon"
                      @click="confirmationDeleteTermes(data.item.id)"
                    />
                    <b-tooltip
                      title="Supprimer"
                      :target="`invoice-row-${data.item.id}-trash-icon`"
                    />
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <QuickEditModal
        v-if="showEditModal"
        :showModal="showEditModal"
        :name="termeInfo.name"
        :slug="termeInfo.slug"
        @ConfirmEdit="termeEdit"
      />
    </b-overlay>
  </div>
</template>
<script>
import axios from "axios";
import websiteURL from "@/websiteURL";
import {
  getListeTerms,
  deleteTermes,
  updateTermes,
} from "@/views/Attributes/Termes.js";
import QuickEditModal from "@/views/QuickEditModal/QuickEdit.vue";
import {
  BOverlay,
  BTable,
  BPagination,
  BSpinner,
  BTooltip,
  BRow,
  BCol,
  BFormInput,
} from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    BPagination,
    BTable,
    BSpinner,
    BTooltip,
    BRow,
    BCol,
    BFormInput,
    QuickEditModal,
  },
  data() {
    return {
      fields: [
        {
          label: "Name",
          key: "name",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Slug",
          key: "slug",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Total",
          key: "count",
          thClass: "text-center",
          tdClass: "text-center",
        },

        {
          label: "Actions",
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isBusy: false,
      attributesSelectedInfo: [],
      listTermes: [],
      nomTerme: "",
      slugTerme: "",
      loading: false,
      showEditModal: false,
      termeInfo: [],
      nomTerme: "",
      slugTerme: "",
    };
  },
  async beforeRouteEnter(to, from, next) {
    const apiUrl =
      websiteURL.WEBSITE + `wp-json/wc/v3/products/attributes/${to.params.id}`;
    const consumerKey = websiteURL.CONSUMERKEY;
    const consumerSecret = websiteURL.CONSUMERSECRET;
    try {
      const response = await axios.get(apiUrl, {
        auth: {
          username: consumerKey,
          password: consumerSecret,
        },
      });
      next((vm) => {
        vm.attributesSelectedInfo = response.data;
      });
    } catch (error) {
      alert("Une erreur s'est produite, veuillez réessayer plus tard", error);
      next(null);
    }
  },
  methods: {
    //Permet l'ajout d'un terme
    async addAttributesTerme() {
      const apiUrl =
        websiteURL.WEBSITE +
        `wp-json/wc/v3/products/attributes/${this.$route.params.id}/terms`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const data = {
          name: this.nomTerme,
          slug: this.slugTerme,
        };
        const response = await axios.post(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.ajoutReussi("info");
      } catch (error) {
        alert(
          "Erreur lors de la récuperation des termes, veuillez réessayer plus tard",
          error
        );
      }
    },
    //Boite dialoque pour confimer l'ajout d'un terme
    confirmAddTerme() {
      this.boxMsg = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir ajouter le terme ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsg = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.addAttributesTerme().then(async () => {
                this.loading = false;
                this.isBusy = true;
                await this.actualisationListeTermes();
                this.isBusy = false;
              });
            }
          }
        });
    },
    //Message toast indiquant que l'ajout est réussi
    ajoutReussi(variant = null) {
      this.$bvToast.toast("Le terme a bien été ajouter", {
        title: `Termes ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    //Boite dialogue spour confirmer ou non la suppresion d'un terme
    confirmationDeleteTermes(idTermes) {
      this.boxMsg = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir supprimer le terme ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          this.boxMsg = value;
          if (value) {
            if (value) {
              this.loading = true;
              await deleteTermes(this.$route.params.id, idTermes).then(
                async () => {
                  this.loading = false;
                  this.deleteReussi("info");
                  this.isBusy = true;
                  await this.actualisationListeTermes();
                  this.isBusy = false;
                }
              );
            }
          }
        });
    },
    //Message toast indiquand que la suppression est réussi
    deleteReussi(variant = null) {
      this.$bvToast.toast("Le terme a bien été supprimer", {
        title: `Termes ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    //Permet de recuperer les informations d'un terme au clique sur icon modification rapide
    async getTermeInfo(item) {
      this.termeInfo = item;
      this.nomTerme = this.termeInfo.name;
      this.slugTerme = this.termeInfo.slug;
      this.showEditModal = !this.showEditModal;
    },
    //Modification info terme
    async termeEdit(info) {
      this.termeInfo.name = info.name;
      this.termeInfo.slug = info.slug;
      this.loading = true;
      await updateTermes(this.$route.params.id, this.termeInfo);
      this.loading = false;
      await this.actualisationListeTermes();
      this.editReussi("info");
    },
    //Affichage d'un message toast comme quoi le terme est bien mis à jour
    editReussi(variant = null) {
      this.$bvToast.toast("Le terme a bien été mis à jour", {
        title: `Termes ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    //Permet d'actualiser la liste des termes du tableau
    async actualisationListeTermes() {
      this.isBusy = true;
      this.listTermes = await getListeTerms(this.$route.params.id);
      this.isBusy = false;
    },
  },
  async created() {
    await this.actualisationListeTermes();
  },
};
</script>
