import axios from "axios";
import websiteURL from "@/websiteURL";

export async function getListeTerms(attributId) {
  const apiUrl =
    websiteURL.WEBSITE +
    `wp-json/wc/v3/products/attributes/${attributId}/terms`;
  const consumerKey = websiteURL.CONSUMERKEY;
  const consumerSecret = websiteURL.CONSUMERSECRET;
  try {
    const response = await axios.get(apiUrl, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
      params: {
        per_page: 20,
      },
    });
    const termes = response.data;
    return termes;
  } catch (error) {
    // Handle errors, e.g., show an error message
    alert(
      "Erreur de chargement de la liste des termes, veuillez réessayer ultérieurement:",
      error
    );
  }
}
export async function deleteTermes(attributId, termesId) {
  const apiUrl =
    websiteURL.WEBSITE +
    `/wp-json/wc/v3/products/attributes/${attributId}/terms/${termesId}`;
  const consumerKey = websiteURL.CONSUMERKEY;
  const consumerSecret = websiteURL.CONSUMERSECRET;
  try {
    const response = await axios.delete(apiUrl, {
      params: {
        force: true,
      },
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });
  } catch (error) {
    alert(
      "Erreur lors de la suppression du termes, veuillez réessayer plus tard",
      error
    );
  }
}

export async function updateTermes(attributeId, terme) {
  const apiUrl =
    websiteURL.WEBSITE +
    `wp-json/wc/v3/products/attributes/${attributeId}/terms/${terme.id}`;
  const consumerKey = websiteURL.CONSUMERKEY;
  const consumerSecret = websiteURL.CONSUMERSECRET;
  const data = {
    name: terme.name,
    slug: terme.slug,
  };
  try {
    const response = await axios.put(apiUrl, data, {
      auth: {
        username: consumerKey,
        password: consumerSecret,
      },
    });

    return response.data;
  } catch (error) {
    // Handle errors, e.g., show an error message
    alert("Erreur lors de la mise à jour du terme:", error);
  }
}
